const EditANightContent = {
    german: {
        date: "13. November 2020",
        arrow: "BLOG << ",
        headline: "Edit-a-Night",
        first: "Free Download: Ein kompaktes Workshopkonzept für (feministisches) Wikipedia-Editing für Bildungsarbeit, feministische Zirkel und alle diejenigen, die mithelfen wollen, Wikipedia diverser zu machen.",
        second: "Der Brockhaus ist 2020 längst passé, unser allgemeines und nicht so allgemeines Wissen holen wir aus der Wikipedia. Umsonst, kollektiv, irgendwie basisdemokratisch. Gutes Projekt, oder? Aber wer schreibt eigentlich dieses „Welt-Wissen“? In einer Umfrage von 2018 stellte die Wikimedia Foundation fest, dass nur 9% der Wikipedia-Autor:innen Frauen sind. Und um die Einträge weiblicher Persönlichkeiten steht es auch nicht gut. Seit Langem beschäftigen sich Eva Königshofen, Hannah Schmedes und Lena Wassermeier mit den Gründen für dieses strukturelle Ungleichgewicht, kritisieren die Konsequenzen – und tun selbst ganz praktisch etwas dagegen: mit feministischen Editierungs-Runden, in denen die Teilnehmenden gemeinsam lernen, wie Wikipedia funktioniert und wie frau sicher geht, dass Admins nicht direkt alles wieder löschen.",
        thirdFirst: "Am 4. Oktober haben die Drei zusammen mit uns, nOu, und dem Forum für Digitales ",
        thirdLinkFirst: "NODE20",
        thirdSecond: ", die „Edit-a-Night“ zur Eröffnung der nOu-Teaserreihe abhgehalten. Die Edit-a-Night ist kollektive Schreibwerkstatt, Empowerment-Group und Reminiszenz an die LAN-Partys der 90er zugleich. 15 interessierte Frauen und Queers waren da, und waren begeistert (der ",
        thirdLinkSecond: "Deutschlandfunk",
        thirdThird: " übrigens auch.)",
        fourth: "Wer es nicht zum Workshop geschafft hat, mehr über das Konzept wissen will oder gleich selbst eine Edit-a-Night veranstalten möchte, kann nun das gesamte Konzept herunterladen.",
        fifthFirst: "Wir danken ",
        fifthLink: "Arbeit und Leben Hessen",
        fifthSecond: " sehr für die finanzielle Unterstützung und die kompetente Betreuung bei der Erstellung des Konzepts!",
        downloadFirst: "Download Konzept Edit-a-Night (PDF)",
        downloadSecond: "Download Konzept Edit-a-Night (DOC)",
        name: "(verfasst von Carolin Zieringer)",
        sponsor: "Gefördert durch",
    },
    english: {
        date: "November 13, 2020",
        arrow: "BLOG << ",
        headline: "Edit-a-Night",
        first: "Free Download: Ein kompaktes Workshopkonzept für (feministisches) Wikipedia-Editing für Bildungsarbeit, feministische Zirkel und alle diejenigen, die mithelfen wollen, Wikipedia diverser zu machen.",
        second: "Der Brockhaus ist 2020 längst passé, unser allgemeines und nicht so allgemeines Wissen holen wir aus der Wikipedia. Umsonst, kollektiv, irgendwie basisdemokratisch. Gutes Projekt, oder? Aber wer schreibt eigentlich dieses „Welt-Wissen“? In einer Umfrage von 2018 stellte die Wikimedia Foundation fest, dass nur 9% der Wikipedia-Autor:innen Frauen sind. Und um die Einträge weiblicher Persönlichkeiten steht es auch nicht gut. Seit Langem beschäftigen sich Eva Königshofen, Hannah Schmedes und Lena Wassermeier mit den Gründen für dieses strukturelle Ungleichgewicht, kritisieren die Konsequenzen – und tun selbst ganz praktisch etwas dagegen: mit feministischen Editierungs-Runden, in denen die Teilnehmenden gemeinsam lernen, wie Wikipedia funktioniert und wie frau sicher geht, dass Admins nicht direkt alles wieder löschen.",
        thirdFirst: "Am 4. Oktober haben die Drei zusammen mit uns, nOu, und dem Forum für Digitales ",
        thirdLinkFirst: "NODE20",
        thirdSecond: ", die „Edit-a-Night“ zur Eröffnung der nOu-Teaserreihe abhgehalten. Die Edit-a-Night ist kollektive Schreibwerkstatt, Empowerment-Group und Reminiszenz an die LAN-Partys der 90er zugleich. 15 interessierte Frauen und Queers waren da, und waren begeistert (der ",
        thirdLinkSecond: "Deutschlandfunk",
        thirdThird: " übrigens auch.)",
        fourth: "Wer es nicht zum Workshop geschafft hat, mehr über das Konzept wissen will oder gleich selbst eine Edit-a-Night veranstalten möchte, kann nun das gesamte Konzept herunterladen.",
        fifthFirst: "Wir danken ",
        fifthLink: "Arbeit und Leben Hessen",
        fifthSecond: " sehr für die finanzielle Unterstützung und die kompetente Betreuung bei der Erstellung des Konzepts!",
        downloadFirst: "Download Konzept Edit-a-Night (PDF)",
        downloadSecond: "Download Konzept Edit-a-Night (DOC)",
        name: "(verfasst von Carolin Zieringer)",
        sponsor: "Sponsored by",
    }

}
export {EditANightContent}