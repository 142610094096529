import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { EditANightContent } from "../../content/blog/edit-a-night"
import { SettingsContext } from "../../contexts/settings"

const EditANightPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = EditANightContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#edit-a-night" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    <b>{content.first}</b>
                </p>
                <p>
                    {content.second}
                </p>
                <p>
                    {content.thirdFirst}
                    <a className="link" href="https://20.nodeforum.org/" target="_blank" rel="noopener noreferrer">{content.thirdLinkFirst}</a>
                    {content.thirdSecond}
                    <a className="link" href="https://www.deutschlandfunk.de/wissen-und-geschlecht-wikipedia-weiblicher-machen.680.de.html?dram:article_id=485263" target="_blank" rel="noopener noreferrer">{content.thirdLinkSecond}</a>
                    {content.thirdThird}
                </p>
                <p>
                    {content.fourth}
                </p>
                <p>
                    <b>
                        {content.fifthFirst}
                        <a className="link-bold" href="https://aul-hessen.de/index.php?id=start" target="_blank" rel="noopener noreferrer">{content.fifthLink}</a>
                        {content.fifthSecond}
                    </b>
                </p>
                <a className="link" href="/documents/blog/ZIM_Edit_a_Night_neu.pdf" target="_blank" rel="noopener noreferrer">{content.downloadFirst}</a>
                <br/>
                <a className="link" href="/documents/blog/ZIM_Edit_a_Night_neu.docx" target="_blank" rel="noopener noreferrer">{content.downloadSecond}</a>
                <p class="small end-of-site">
                    {content.name}
                </p>
            </div> 
            <h4>{content.sponsor}</h4>
            <div className="image-banner end-of-site">
                <img className="image-middle" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Arbeit-und-Leben.png" : "/logos/Kooperationspartner/bw/bw_Logo_Arbeit-und-Leben.png"} alt="Logo Arbeit und Leben"/>
            </div>   
            </section>
        </Template >
    )
}

export default EditANightPage